<template>
  <content-layout :loading="loading" :breadcrumbs-items="breadcrumbsItems">
    <v-card flat outlined rounded="lg">
      <v-tabs
        v-model="filtersState.tab"
        background-color="white"
        style="border-radius: 8px"
      >
        <v-tab
          v-for="item in displayedTabs"
          :key="item.text"
          :disabled="dataTable.loading"
        >
          {{ item.text }}
        </v-tab>
      </v-tabs>
    </v-card>

    <v-toolbar flat color="background">
      <template v-if="canCreateOrders">
        <v-menu v-if="isOrderManualCreationActive" offset-y>
          <template v-slot:activator="{ attrs, on }">
            <cz-button
              :title="$t('customer.createNewOrder')"
              color="button"
              dark
              :icon-src="mdiPlusCircleOutline"
              v-on="on"
              v-bind="attrs"
            />
          </template>
          <v-list>
            <v-list-item link @click="onUploadOrderClicked">
              <v-list-item-icon class="ml-1"
                ><cz-icon :src="mdiUploadOutline"
              /></v-list-item-icon>
              <v-list-item-title>{{
                $t('customer.uploadOrder')
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="onNewOrderClicked('order')">
              <v-list-item-icon class="ml-1"
                ><cz-icon :src="mdiFilePlusOutline"
              /></v-list-item-icon>
              <v-list-item-title>{{
                $t('customer.createManualOrder')
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              @click="onNewOrderClicked('order-items')"
              v-if="isOrderItemsActive"
            >
              <v-list-item-icon class="ml-1"
                ><cz-icon :src="mdiInvoiceListOutline"
              /></v-list-item-icon>
              <v-list-item-title>{{
                $t('customer.createManualOrderWithItems')
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <cz-button
          v-else
          :title="$t('customer.createNewOrder')"
          color="primary"
          :icon-src="mdiPlus"
          @click="onUploadOrderClicked"
        />
      </template>
      <v-spacer />

      <div class="d-flex align-center" style="gap: 20px">
        <cz-button
          :title="$t('common.filters')"
          :icon-src="
            hasActiveFilters(filtersState.currentFilters)
              ? mdiFilterCheckOutline
              : mdiFilterOutline
          "
          :outlined="!hasActiveFilters(filtersState.currentFilters)"
          :color="hasActiveFilters ? 'primary' : 'primary'"
          @click="filtersBar.show = true"
        />
      </div>
    </v-toolbar>
    <cz-data-table
      :height="canCreateOrders ? 'calc(100vh - 350px)' : 'calc(100vh - 350px)'"
      v-bind="dataTable"
      :columns="columnsForDisplay"
      :options.sync="filtersState.options"
      @click:row="onOrderClicked"
      fixed-header
      :expand-icon="mdiChevronDown"
      single-expand
      :expanded.sync="expanded"
      :show-expand="currentSelectedTab.expanded"
      @update:expanded="loadOrderSubmissions"
      item-key="_id"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-3">
          <v-card flat color="background">
            <v-card-title>רשימת הגשות עבור הזמנה</v-card-title>
            <v-card-text>
              <cz-data-table
                hide-default-footer
                v-bind="submissionsTable"
                @click:row="onSubmissionClicked"
              >
                <template #item.issueDate="{ item }">
                  <span>{{ formatDateToDisplay(item.issueDate) }}</span>
                </template>
                <template #item.createdAt="{ item }">
                  <span>{{ formatDateToDisplay(item.createdAt) }}</span>
                </template>

                <template #item.status="{ item }">
                  {{ orderSubmissionStatusText[item.status] }}
                </template>
                <template #item.nav>
                  <cz-icon
                    :size="20"
                    :src="mdiChevronLeft"
                    color="textPrimary"
                  />
                </template>
              </cz-data-table>
            </v-card-text>
          </v-card>
        </td>
      </template>
      <template #item.status="{ item }">
        {{ orderStatusText[item.status] }}
      </template>
      <template #item.shortDesc="{ item }">
        <div
          class="d-flex align-center"
          style="column-gap: 10px"
          v-if="item.title"
        >
          <span>{{ item.title }}</span>
          <v-menu v-if="item.description" offset-y open-on-hover>
            <template v-slot:activator="{ attrs, on }">
              <cz-icon
                v-if="item.description"
                v-on="on"
                v-bind="attrs"
                :src="mdiInformationOutline"
                color="primary"
              />
            </template>
            <v-card>
              <v-card-text class="text-pre-wrap">
                {{ item.description }}
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
      </template>
      <template #item.vendorId="{ item }">
        <div v-if="item.vendor">
          <vendor-info-menu :vendor="item.vendor" />
        </div>
        <div v-else>
          <div>{{ item.vendorId }}</div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-on="on"
                v-bind="attrs"
                class="d-flex align-center"
                style="column-gap: 5px"
              >
                <div>{{ $t('customer.pendingVendorRegistration') }}</div>
                <cz-icon
                  :size="16"
                  :src="mdiInformationOutline"
                  color="primary"
                />
              </div>
            </template>
            <span>{{ $t('customer.pendingVendorRegistrationInfo') }}</span>
          </v-tooltip>
        </div>
      </template>
      <template #item.issueDate="{ item }">
        <span v-if="item.issueDate">{{
          formatDateToDisplay(item.issueDate)
        }}</span>
      </template>
      <template #item.createdAt="{ item }">
        <span v-if="item.createdAt">{{
          formatDateToDisplay(item.createdAt)
        }}</span>
      </template>
      <template #item.totalAmount="{ item }">
        {{ formatAmountWithCurrency(item.totalAmount, item.currency) }}
      </template>
      <template #item.amountLeft="{ item }">
        {{ formatAmountWithCurrency(item.amountLeft, item.currency) }}
      </template>
      <template #item.vatAmount="{ item }">
        {{ formatAmountWithCurrency(item.vatAmount, item.currency) }}
      </template>
      <template #item.netAmount="{ item }">
        {{ formatAmountWithCurrency(item.netAmount, item.currency) }}
      </template>
      <template #item.orderDocument="{ item }">
        <a
          v-if="item.orderDocument"
          class="font-weight-semibold"
          :href="item.orderDocument.url"
          target="_blank"
          >{{ $t('customer.orders.viewOrderDocument') }}</a
        >
      </template>
      <template #item.authorizerUser="{ item }">
        <user-info-menu
          :user="item.authorizerUser"
          v-if="item.authorizerUser"
        />
      </template>
      <template #item.purchaserUser="{ item }">
        <user-info-menu :user="item.purchaserUser" v-if="item.purchaserUser" />
      </template>
      <template #item.cancelledByUser="{ item }">
        <user-info-menu
          :user="item.cancelledByUser"
          v-if="item.cancelledByUser"
        />
      </template>
      <template #item.currentApproverUserId="{ item }">
        <user-info-menu
          :user="item.currentApproverUser"
          v-if="item.currentApproverUser"
        />
      </template>
      <template #item.nav>
        <cz-icon :size="26" :src="mdiChevronLeft" color="textPrimary" />
      </template>
    </cz-data-table>

    <new-order-dialog
      v-if="newOrderDialog.show"
      v-model="newOrderDialog.show"
      v-bind="newOrderDialog"
      @close="newOrderDialog.show = false"
      @continue="onNewOrder"
    />

    <cancel-order-dialog
      v-if="cancelOrderDialog.show"
      v-model="cancelOrderDialog.show"
      v-bind="cancelOrderDialog"
      @close="cancelOrderDialog.show = false"
      @cancel="cancelOrder"
    />

    <filters-bar
      v-model="filtersBar.show"
      v-bind="filtersBar"
      @close="filtersBar.show = false"
      @on-filter="onFilter"
      :filter-fields="filtersState.currentFilters"
    >
      <template
        #prepend-fields
        v-if="currentSelectedTab.id === 'pending-approval'"
      >
        <v-switch
          v-model="showOrdersPendingToMe"
          v-if="currentSelectedTab.id === 'pending-approval'"
          class="pa-0 ma-0"
        >
          <template #label>
            <span>{{
              $t('customer.orders.filters.showOrdersPendingToMe')
            }}</span>
          </template>
        </v-switch>
      </template>
    </filters-bar>
  </content-layout>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import {
  getOrders,
  getUserRoles,
  updateOrder,
  createOrder,
  uploadFiles,
  getOrderSubmissions
} from '@/core/api';
import { formatDateToDisplay } from '@/shared/services/dates.service';
import { formatAmountWithCurrency } from '@/shared/services/currency.service';
import {
  mdiMessageDraw,
  mdiCloseCircle,
  mdiInformation,
  mdiInformationOutline,
  mdiArrowDown,
  mdiFilterOutline,
  mdiFilterCheckOutline,
  mdiPlus,
  mdiPlusCircle,
  mdiPlusCircleOutline,
  mdiFileEyeOutline,
  mdiChevronLeft,
  mdiChevronDown,
  mdiNumeric,
  mdiAccountCardOutline,
  mdiAccount,
  mdiCalendarRangeOutline,
  mdiTagMultipleOutline,
  mdiUploadOutline,
  mdiFilePlusOutline,
  mdiInvoiceListOutline
} from '@mdi/js';
import { CzButton, CzIcon, CzDataTable } from '@/components';
import RoleType from '@/shared/types/RoleType';
import { mapActions, mapGetters } from 'vuex';
import OrderStatus from '@/shared/types/OrderStatus';
import cloneDeep from 'lodash.clonedeep';
import { userHasOneOfRolesWithName } from '@/shared/services/rbac/rbac.service';
import VendorInfoMenu from '../components/VendorInfoMenu.vue';
import UserInfoMenu from '@/domain/auth/components/UserInfoMenu.vue';
import { typesMixin } from '@/shared/mixins';
import store from '@/core/store';
import {
  formatFiltersForServer,
  hasActiveFilters
} from '@/shared/services/filters.service';
import { DOCUMENT_PREDICTION_TIMEOUT } from '@/domain/shared/constants';

export default {
  name: 'OrdersPage',
  components: {
    ContentLayout: () => import('@/layouts/ContentLayout.vue'),
    NewOrderDialog: () => import('../components/NewOrderDialog.vue'),
    CancelOrderDialog: () => import('../components/CancelOrderDialog.vue'),
    FiltersBar: () => import('@/domain/shared/components/FiltersBar.vue'),
    UserInfoMenu,
    CzButton,
    CzIcon,
    CzDataTable,
    VendorInfoMenu
  },
  mixins: [typesMixin],
  props: {
    selectedTabId: {
      type: String,
      default: 'pending-orders'
    },
    newOrderModeType: {
      type: String
    }
  },
  computed: {
    ...mapFields('auth', ['accountId', 'currentUser', 'abilities', 'account']),
    ...mapFields('customer', ['currentOrdersTabId']),
    ...mapFields('orders', ['filtersState']),
    ...mapGetters('auth', [
      'isOrderManualCreationActive',
      'isOrderItemsActive'
    ]),
    canCreateOrders() {
      return this.abilities.can('create', 'orders');
    },
    breadcrumbsItems() {
      return [
        {
          text: this.$t('common.homepage'),
          to: {
            name: 'customer'
          },
          disabled: false,
          exact: true,
          isHome: true
        },
        {
          text: this.$t('customer.ordersPageTitle'),
          disabled: true,
          exact: true
        }
      ];
    },
    showTableExpander() {},
    currentSelectedTab() {
      return this.displayedTabs[this.filtersState.tab];
    },
    columnsForDisplay() {
      return this.dataTable.headers.filter((column) => {
        if (this.isOrderManualCreationActive) {
          return (
            !this.currentSelectedTab.excludeColumns.includes(column.value) &&
            column.value !== 'orderDocument'
          );
        } else {
          return !this.currentSelectedTab.excludeColumns.includes(column.value);
        }
      });
    },
    displayedTabs() {
      if (
        userHasOneOfRolesWithName(this.currentUser, [
          RoleType.CUSTOMER_PURCHASER,
          RoleType.CUSTOMER_OBSERVER
        ])
      ) {
        return this.tabs;
      } else {
        return this.tabs.filter((item) => item.id !== 'pending-orders');
      }
    }
  },
  created() {
    const index = this.displayedTabs.findIndex(
      (item) => item.id === this.selectedTabId
    );

    if (index === -1 && this.filtersState.tab === -1) {
      this.filtersState.tab = 0;
    } else if (this.filtersState.tab === -1) {
      this.filtersState.tab = index;
    }

    if (this.filtersState.currentFilters === null) {
      this.initializeFilters();
    }

    if (this.newOrderModeType === 'upload-order') {
      this.newOrderDialog.show = true;
    } else if (this.newOrderModeType === 'manual-order') {
      this.onNewOrderClicked();
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.name !== 'customer-order') {
      store.dispatch('orders/resetFiltersState', {}, { root: true });
    }
    next();
  },
  data() {
    return {
      mdiInvoiceListOutline,
      mdiPlusCircleOutline,
      mdiPlusCircle,
      mdiFilePlusOutline,
      mdiUploadOutline,
      mdiMessageDraw,
      mdiFileEyeOutline,
      mdiCloseCircle,
      mdiInformation,
      mdiInformationOutline,
      mdiArrowDown,
      mdiChevronDown,
      mdiChevronLeft,
      mdiFilterOutline,
      mdiFilterCheckOutline,
      mdiPlus,
      loading: false,
      expanded: [],
      authorizers: [],
      purchasers: [],
      showOrdersPendingToMe: false,
      filtersLoaded: false,
      filtersBar: {
        show: false
      },
      tabs: [
        {
          id: 'pending-orders',
          expanded: false,
          text: this.$t('customer.pendingOrders'),
          statuses: [
            OrderStatus.NEW,
            OrderStatus.NEW_PREDICTED,
            OrderStatus.RETURNED
          ],
          excludeColumns: [
            'cancelledByUser',
            'authorizerUser',
            'purchaserUser',
            'currentApproverUserId',
            'amountLeft'
          ],
          actions: []
        },
        {
          id: 'pending-approval',
          expanded: false,
          text: this.$t('customer.pendingApprovalOrders'),
          statuses: [OrderStatus.PENDING_APPROVAL],
          excludeColumns: [
            'status',
            'cancelledByUser',
            'authorizerUser',
            'purchaserUser'
          ],
          actions: []
        },
        {
          id: 'active-orders',
          expanded: true,
          text: this.$t('customer.activeOrders'),
          statuses: [OrderStatus.OPEN, OrderStatus.OPEN_PENDING_VENDOR_REG],
          excludeColumns: ['cancelledByUser', 'currentApproverUserId'],
          actions: []
        },
        {
          id: 'closed-orders',
          expanded: true,
          text: this.$t('customer.closedOrders'),
          statuses: [OrderStatus.CLOSED],
          excludeColumns: [
            'status',
            'cancelledByUser',
            'actions',
            'currentApproverUserId'
          ],
          actions: ['show']
        },
        {
          id: 'cancelled-orders',
          expanded: true,
          text: this.$t('customer.cancelledOrders'),
          statuses: [OrderStatus.CANCELLED],
          excludeColumns: [
            'status',
            'authorizerUser',
            'actions',
            'currentApproverUserId'
          ],
          actions: ['show']
        },
        {
          id: 'rejected-orders',
          expanded: false,
          text: this.$t('customer.rejectedOrders'),
          statuses: [OrderStatus.REJECTED],
          excludeColumns: [
            'status',
            'authorizerUser',
            'actions',
            'currentApproverUserId'
          ],
          actions: ['show']
        }
      ],
      submissionsTable: {
        hideDefaultFooter: true,
        hideDefaultHeader: false,
        loading: false,
        items: [],
        dense: true,
        columns: [
          {
            text: this.$t('vendor.orders.invoiceNumber'),
            value: 'documentNumber',
            sortable: true,
            width: 150
          },
          {
            text: this.$t('customer.vendorSubmissions.submissionStatus'),
            value: 'status',
            sortable: true
          },
          {
            text: this.$t('vendor.orderSubmissions.createdAt'),
            value: 'createdAt',
            sortable: true
          },
          {
            text: this.$t('vendor.orderSubmissions.issueDate'),
            value: 'issueDate',
            sortable: true
          },
          {
            text: this.$t('vendor.orderSubmissions.totalAmount'),
            value: 'totalAmount',
            sortable: false,
            width: 150
          },
          {
            text: '',
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'nav',
            align: 'left',
            sortable: false
          }
        ]
      },
      dataTable: {
        loading: false,
        serverItemsLength: -1,
        items: [],
        headers: [
          {
            text: this.$t('customer.orders.orderNumber'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'orderNumber',
            sortable: true,
            filterable: true,
            width: 130
          },
          {
            text: this.$t('customer.orders.orderStatus'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'status',
            sortable: true,
            filterable: true,
            width: 150
          },
          {
            text: this.$t('customer.orders.orderShortDesc'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'shortDesc',
            sortable: false
          },
          {
            text: this.$t('customer.orders.createdAt'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'createdAt',
            sortable: true,
            filterable: true,
            width: 140
          },
          {
            text: this.$t('customer.orders.issueDate'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'issueDate',
            sortable: true,
            filterable: true,
            width: 140
          },

          {
            text: this.$t('customer.orders.vendor'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'vendorId',
            sortable: true,
            filterable: true
          },
          {
            text: this.$t('customer.orders.netAmount'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'netAmount',
            sortable: false,
            width: 100
          },
          {
            text: this.$t('customer.orders.vatAmount'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'vatAmount',
            sortable: false,
            width: 100
          },
          {
            text: this.$t('customer.orders.totalAmount'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'totalAmount',
            sortable: true,
            width: 170
          },
          {
            text: this.$t('customer.orders.amountLeft'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'amountLeft',
            sortable: true,
            width: 130
          },
          {
            text: this.$t('customer.orders.orderDocument'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'orderDocument',
            sortable: false
          },
          {
            text: this.$t('customer.orders.pendingApprovalBy'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'currentApproverUserId',
            sortable: true
          },
          {
            text: this.$t('customer.orders.orderCancelledBy'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'cancelledByUser',
            sortable: false
          },
          {
            text: '',
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'nav',
            align: 'left',
            sortable: false
          }
        ]
      },
      newOrderDialog: {
        show: false,
        loading: false,
        loadingTitle: '',
        title: this.$t('customer.createNewOrder'),
        inputLabel: this.$t('customer.orders.orderDocument')
      },
      cancelOrderDialog: {
        show: false,
        orderId: '',
        orderNumber: '',
        loading: false
      }
    };
  },
  methods: {
    ...mapActions('ui', ['showSuccessToastMessage', 'showErrorToastMessage']),
    ...mapActions('orders', ['resetFiltersState']),
    hasActiveFilters,
    formatDateToDisplay,
    formatAmountWithCurrency,

    onTabChange(tab) {},

    onNewOrderClicked(documentType = 'order') {
      this.$router.push({
        name: 'customer-order',
        params: {
          id: 'new',
          documentType
        }
      });
    },

    onUploadOrderClicked() {
      this.newOrderDialog.show = true;
    },

    onOrderClicked(item) {
      this.$router.push({
        name: 'customer-order',
        params: {
          id: item._id
        }
      });
    },
    onSubmissionClicked(item) {
      this.$router.push({
        name: 'customer-submission',
        params: {
          submissionId: item._id
        }
      });
    },

    async countOrders() {
      const { total } = await getOrders({
        accountId: this.accountId,
        status: { $in: this.currentSelectedTab.statuses },
        $limit: 0
      });
      this.dataTable.serverItemsLength = total;
    },
    async loadData() {
      const { itemsPerPage, page, sortBy, sortDesc } =
        this.filtersState.options;

      const $skip = (page - 1) * itemsPerPage;

      const query = {
        accountId: this.accountId,
        status: { $in: this.currentSelectedTab.statuses },
        $limit: itemsPerPage,
        $skip
      };

      if (this.currentOrdersTabId === 'pending-approval') {
        query.$or = [
          { currentApproverUserId: this.currentUser._id },
          {
            createdByUserId: this.currentUser._id
          }
        ];
      }

      if (sortBy?.length) {
        query.$sort = {
          [`${sortBy[0]}`]: sortDesc[0] === true ? -1 : 1
        };
      }

      if (this.filtersState.currentFilters?.length) {
        const formattedFilters = formatFiltersForServer(
          cloneDeep(this.filtersState.currentFilters)
        );
        // const formattedFilters = this.currentFilters;
        formattedFilters.forEach((field) => {
          query[field.fieldname] = field.value;
        });
      }

      if (this.showOrdersPendingToMe) {
        query['currentApproverUserId'] = this.currentUser._id;
      }

      try {
        this.dataTable.loading = true;
        this.dataTable.items = [];

        const { data, total } = await getOrders(query);
        this.dataTable.serverItemsLength = total;
        this.dataTable.items = data;
      } finally {
        this.dataTable.loading = false;
      }
    },
    async loadOrderSubmissions(items) {
      this.submissionsTable.items = [];

      if (!items.length) {
        return;
      }

      const order = items[0];

      if (order.submissions) {
        this.submissionsTable.items = order.submissions;
        return;
      }

      try {
        this.submissionsTable.loading = true;

        const { data } = await getOrderSubmissions({
          relatedOrderId: order._id,
          customerAccountId: this.accountId,
          status: { $nin: ['new', 'new_predicted'] }
        });

        this.submissionsTable.items = data;
        order.submissions = data;
      } finally {
        this.submissionsTable.loading = false;
      }
    },
    async onNewOrder(orderDoc) {
      try {
        this.newOrderDialog.loading = true;
        this.newOrderDialog.loadingTitle = this.$t(
          'customer.orders.creatingOrder'
        );
        const [orderDocFile] = await uploadFiles([orderDoc], this.accountId);
        const order = await createOrder({
          orderDocumentFileId: orderDocFile._id,
          accountId: this.accountId,
          documentType: 'order',
          status: 'new'
        });

        this.newOrderDialog.loadingTitle = this.$t(
          'customer.orders.processingOrder'
        );
        // after order has been created, we need to wait about 4-5 seconds to order to be processed
        // In the future it will be done via messaging queue or via websockets.
        setTimeout(() => {
          this.handleProcessedOrder(order._id);
        }, DOCUMENT_PREDICTION_TIMEOUT);
      } catch (error) {
        this.newOrderDialog.loading = false;
        this.showErrorToastMessage(error.message);
      }
    },
    /**
     * Handle a proceseed order
     * We expect that the order will be in status new_predicted
     */
    async handleProcessedOrder(orderId) {
      const { data, total } = await getOrders({
        accountId: this.accountId,
        _id: orderId
      });
      this.dataTable.items.unshift(data[0]);
      this.$router.push({
        name: 'customer-order',
        params: {
          id: orderId
        }
      });
    },
    onFilter(fields, clear) {
      if (clear === true && this.showOrdersPendingToMe) {
        this.showOrdersPendingToMe = false;
      }

      this.filtersBar.show = false;
      this.filtersState.currentFilters = cloneDeep(fields);
      this.loadData();
    },

    initializeFilters() {
      let initialFilters = [
        {
          fieldname: 'orderNumber',
          label: this.$t('customer.orders.filters.orderNumber'),
          type: 'input',
          inputType: 'number',
          icon: mdiNumeric,
          value: ''
        },
        {
          fieldname: 'vendorId',
          label: this.$t('customer.orders.filters.vendor'),
          type: 'vendors',
          items: [],
          icon: mdiAccountCardOutline,
          value: ''
        },
        {
          fieldname: 'createdByUserId',
          label: this.$t('customer.orders.createdByUser'),
          type: 'autocomplete',
          itemText: 'fullName',
          itemValue: '_id',
          items: this.purchasers,
          value: [],
          multiple: true,
          icon: mdiAccount,
          operator: 'in'
        },
        {
          fieldname: 'orderApproversUserIds',
          label: this.$t('customer.orders.approvers'),
          type: 'autocomplete',
          itemText: 'fullName',
          itemValue: '_id',
          itemsHandler: () => this.purchasers.concat(this.authorizers),
          value: [],
          multiple: true,
          icon: mdiAccount,
          operator: 'in'
        },
        {
          fieldname: 'issueDate',
          label: this.$t('customer.orders.filters.issueDate'),
          type: 'date',
          value: '',
          rules: 'date_format:dd/MM/yyyy',
          icon: mdiCalendarRangeOutline,
          operator: 'ge'
        },
        {
          fieldname: 'tags',
          label: this.$t('customer.orders.filters.tags'),
          type: 'tags',
          items: this.account.systemTags,
          value: [],
          icon: mdiTagMultipleOutline,
          operator: 'in'
        }
      ];

      if (!this.isOrderManualCreationActive) {
        initialFilters = initialFilters.filter(
          (item) => item.fieldname !== 'orderApproversUserIds'
        );
      }

      this.filtersState.currentFilters = cloneDeep(initialFilters);
    },

    onCancelOrder(order) {
      this.cancelOrderDialog.orderId = order._id;
      this.cancelOrderDialog.orderNumber = order.orderNumber;
      this.cancelOrderDialog.show = true;
    },
    async cancelOrder(id) {
      try {
        this.cancelOrderDialog.loading = true;
        // update the order
        await updateOrder(id, {
          status: OrderStatus.CANCELLED,
          accountId: this.accountId
        });
        this.cancelOrderDialog.show = false;
        this.showSuccessToastMessage(
          this.$t('customer.orders.orderCancelledSuccess')
        );

        // remove the order locally from the list
        const index = this.dataTable.items.findIndex((item) => item._id === id);
        if (index !== -1) {
          this.dataTable.items.splice(index, 1);
        }
      } catch (error) {
        this.showErrorToastMessage(error.message);
      } finally {
        this.cancelOrderDialog.loading = false;
      }
    },
    async loadOrderFilters() {
      const { data: userRoles } = await getUserRoles({
        accountId: this.accountId,
        role: {
          $in: [RoleType.CUSTOMER_AUTHORIZER, RoleType.CUSTOMER_PURCHASER]
        },
        $populate: ['user']
      });

      this.authorizers = userRoles
        .filter((item) => item.role === RoleType.CUSTOMER_AUTHORIZER)
        .map((item) => item.user);

      this.purchasers = userRoles
        .filter((item) => item.role === RoleType.CUSTOMER_PURCHASER)
        .map((item) => item.user);

      const _filters = cloneDeep(this.filtersState.currentFilters);

      const approversFilter = _filters.find(
        (item) => item.fieldname === 'approversUserIds'
      );

      if (approversFilter) {
        approversFilter.items = this.authorizers.concat(this.purchasers);
      }

      const createdByFilter = _filters.find(
        (item) => item.fieldname === 'createdByUserId'
      );

      if (createdByFilter) {
        createdByFilter.items = this.purchasers;
      }

      this.filtersState.currentFilters = _filters;
      this.filtersLoaded = true;
    }
  },
  watch: {
    'filtersState.tab': {
      handler() {
        if (
          this.showOrdersPendingToMe &&
          this.currentSelectedTab?.id !== 'pending-approval'
        ) {
          this.showOrdersPendingToMe = false;
        }
        this.currentOrdersTabId = this.currentSelectedTab?.id;
        this.expanded = [];
        // this.resetFiltersState();
        this.loadData();
      }
    },
    'filtersState.options': {
      handler() {
        this.loadData();
      }
    },
    'filtersBar.show': {
      handler(val) {
        if (val && !this.filtersLoaded) {
          this.loadOrderFilters();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep tbody {
  tr:hover {
    background-color: transparent !important;
  }
}

::v-deep tbody tr :hover {
  cursor: pointer;
}

::v-deep
  .v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none;
}
</style>
